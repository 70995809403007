.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.view-liveness {
    @media only screen and (min-width: 700px) {
        max-height: 700px;
        height: 700px;
        width: 690px;
        max-width: 690px;
    }
}

span.amplify-text {
    text-align: left;
}

.amplify-liveness-figures {
    justify-content: center;
}

.amplify-alert__heading {
    font-family: 'Albert Sans';
    font-weight: 500;
    text-align: left;
}

.amplify-alert__body {
    text-align: left;
    font-family: 'Albert Sans';
    font-weight: 400;
    margin-top: 12px;
}

.amplify-liveness-start-screen-warning {
    color: #000000;
    background-color: #FFFBE6;
}

.amplify-icon {
    color: #FAAD14;
}

.amplify-liveness-instruction-list {
    order: 1;
}

.amplify-liveness-figures {
    order: 2;
}

.amplify-liveness-start-screen-warning {
    display: flex;
    flex-direction: column-reverse;
}

.amplify-liveness-popover {
    margin-left: auto;
}

.amplify-icon svg {
    width: 1.5em !important;
    height: 1.5em !important;
}

.amplify-liveness-cancel-button {
    margin-left: auto;
}

.amplify-liveness-recording-icon-container {
    display: none;
}

.amplify-liveness-toast__message {
    font-size: 14px;
}