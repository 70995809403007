.primary-button {
    background-color: #2D9A47;
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: 700;
    border: 1px solid #2D9A47;
    cursor: pointer;
    height: 30px;
    font-size: 11px;
    transition: all 0.3s ease-in-out 0s;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}