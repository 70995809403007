.label {
    font-weight: 300;
    font-style: normal;
    color: #1e2421;
  }
  
  .small {
    font-size: 14px;
  }

  .medium {
    font-size: 18px;
  }
  
  .large {
    font-size: 24px;
  }
  
  .extra-large {
    font-size: 32px;
  }