.card {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    margin: 10px 5px 0;
    padding: 0;
    background-color: #ffffff;
    border-radius: 6px;
    color: #2b2b2b; 
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    border: 1px solid #d7d7d7;
    background-color: #fafafa; 
    align-items: center;
    padding: 12px;
  }