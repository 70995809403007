.footer {
    position: absolute;
    bottom: 7px;
    left: 0;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.footer .label {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #004213;
    font-weight: 400;

    @media only screen and (max-width:700px) {
        font-size: 10px;
    }

    @media only screen and (max-width:620px) {
        font-size: 8px;
    }

    @media only screen and (max-width: 414px) {
        font-size: 6px;
    }
}

.footer img {
    width:12px;
    height: 12px;

    @media only screen and (max-width: 700px) {
        width: 10px;
        height: 10px;
    }

    @media only screen and (max-width: 620px) {
        width: 8px;
        height: 8px;
    }

    @media only screen and (max-width: 414px) {
        width: 6px;
        height: 6px;
    }
}