.logoContainer {
    padding-top: 1.75rem;
    padding-bottom: 1.125rem;
    justify-content: center;

    @media only screen and (min-width: 768px) {
        img {
            max-width: 442px;
        }
    }
}

.logoContainer img {
    max-width: 210PX;
    max-height: 56px;
}